<template>
  <div class="store-container">
    <a href="https://apps.apple.com/app/apple-store/id1637935427?pt=125379311&ct=website&mt=8" target="_blank">
      <img class="store_emblem" :src="appleSourceByLanguage" :alt="$t('home.alt.apple')" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.memodio.app&referrer=utm_source%3Dwebsite"
      target="_blank"
    >
      <img class="store_emblem" :src="playSourceByLanguage" :alt="$t('home.alt.android')" />
    </a>
  </div>
</template>

<script>
export default {
  name: "StoreContainer",
  computed: {
    playSourceByLanguage() {
      return "/images/store_badges/google_play_" + this.$i18n.locale + ".webp"
    },
    appleSourceByLanguage() {
      return "/images/store_badges/apple_store_" + this.$i18n.locale + ".svg"
    },
  },
}
</script>

<style scoped lang="scss">
.store-container {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.store_emblem {
  max-height: 48px;
  min-height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  width: 136px;
}
</style>
